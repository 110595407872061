import { template as template_0d3e7ee7d5a44746a083a581d6bf128c } from "@ember/template-compiler";
const FKLabel = template_0d3e7ee7d5a44746a083a581d6bf128c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
