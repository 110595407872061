import { template as template_ba9cca454a3d4e9ab22f9a37cbee46e9 } from "@ember/template-compiler";
const WelcomeHeader = template_ba9cca454a3d4e9ab22f9a37cbee46e9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
