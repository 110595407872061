import { template as template_0b194e535aa741c8bdddf51d69267b5f } from "@ember/template-compiler";
const FKText = template_0b194e535aa741c8bdddf51d69267b5f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
