import { template as template_3571635225294487b98ea7b906bba72e } from "@ember/template-compiler";
const FKControlMenuContainer = template_3571635225294487b98ea7b906bba72e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
